import React, { Component } from "react";

import Section from "./section";
import Footer from "../../components/Footer/footer";
import HomeButton from "../../components/common/HomeButton.js";

class Gallery extends Component {
  render() {
    return (
      <React.Fragment>
        <HomeButton />
        {/* Importing section */}
        <Section />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Gallery;
