import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import homeImg from "../../assets/images/home.jpg";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="hero-section" id="home">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="hero-wrapper mb-4">
                  <p className="font-16 text-uppercase">
                    Welcome to the OSMEthiopia
                  </p>
                  <h1 className="hero-title mb-4">
                    Building Maps, Promoting Community Mapping and ICT
                    <span className="text-primary"> Ethiopia</span>
                  </h1>

                  {/* <p>
                    OSMEthiopia Is a crowdsourced mapping project aiming to put
                    rural Ethiopia on the map, particularly those areas where
                    girls are at risk of Female Genital Mutilation (FGM).
                  </p> */}
                  <p>
                  Group for OSM community in Ethiopia - for sharing ideas and doing collaborative work in the region. We also represent all mappers, geospatial technology users, open data, and open source software project contributors in 🇪🇹
                  </p>

                  <div className="mt-4">
                    <Link to="#" className="btn btn-primary mt-2 mr-2">
                      Be s member
                    </Link>
                    <Link to="#" className="btn btn-success mt-2 mr-2 ml-1">
                      Learn more
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg={6} sm={8}>
                <div className="home-img mt-5 mt-lg-0">
                  <img
                    src={homeImg}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
