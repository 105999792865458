import React from "react";
import classNames from "classnames";
import FeatherIcon from "feather-icons-react";
import "./HomeButton.sass";

const HomeButton = ({ onClick, btnDark, disabled, bg, sm, xs, block, img }) => {
  return (
    <button
      className={classNames("gis-btn", {
        "btn-gis-dark": btnDark,
        disabled: disabled,
        xs: xs,
        sm: sm,
        bg: bg,
        block: block,
        img: img,
      })}
      onClick={() => (window.location.href = "/")}
    >
      <i>
        <FeatherIcon
          icon="map-pin"
          className="icon-dual-light icons-sm mt-1 mr-2"
        />
      </i>
      {"Return Home"}
    </button>
  );
};

export default HomeButton;
