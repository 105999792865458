import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import FeatureBox from "./feature-box";

//Import Images
import feature1 from "../../assets/images/features/1.jpg";
import farmer from "../../assets/images/features/farmer.jpg";
import sotm from "../../assets/images/features/sotm.jpg";
import city from "../../assets/images/features/city.jpg";
import transport from "../../assets/images/features/transport.jpg";
import feature4 from "../../assets/images/features/4.jpg";
import flood from "../../assets/images/features/flood.jpg";
import foss from "../../assets/images/features/foss.jpg";
import refugee from "../../assets/images/features/refugee.jpg";
import feature8 from "../../assets/images/features/8.jpg";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smallFeatures1: [{ title: "Culture" }, { title: "Heritage" }],
      smallFeatures2: [{ title: "Art" }, { title: "Ethiopian " }],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="activities">
          <Container>
            <SectionTitle
              subtitle="Activities"
              title="Key activities of the OSMEthiopia"
              desc="We facilitates all possibilities in the GIS to implement projects by technological advancement"
            />
            <Row>
              <Col lg={5}>
                <div>
                  <FeatureBox
                    icon="bar-chart-2"
                    title="OpenStreetMap Internship"
                    smallFeatures={this.state.smallFeatures1}
                    desc="OpenStreetMap Ethiopia aspires to provide diverse internship programs to open up the community greater and furnish direction for the foremost steps into the world of Open mapping, open source, and open data. Additionally, soft skills transferred through workshops which include career guidance, networking, and sharing experiences. Finally, some outstanding interns are selected for our projects. "
                    link="#"
                  />
                </div>
              </Col>
    
              <Col lg={5} sm={8} className="ml-lg-auto">
                <Card className="border border-light shadow-none mt-5 mt-lg-0">
                  <CardHeader className="border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={feature1}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5 pt-5">
              <Col lg={5} sm={8}>
                <Card className="border border-light shadow-none">
                  <CardHeader className=" border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={farmer}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} className="ml-lg-auto">
                <div className="mt-4 mt-lg-0">
                  <FeatureBox
                    icon="pie-chart"
                    title="Supporting Noble Farms Ethiopia Ltd in Addis Ababa"
                    smallFeatures={this.state.smallFeatures2}
                    desc="OpenStreetMap Ethiopia in collaboration with OpenGIS Ethiopia is working with Noble Farms Ethiopia Limited, private agricultural training and inputs provider to introduce open mapping GIS  tools and technology to improve efficiency in their work.Noble Farms Ethiopia Limited provides farmers with inputs that include seeds, agricultural machinery like tractors, and training on post-harvesting."
                    link="#"
                  />
                </div>
              </Col>
            </Row>
            {/*  */}
            <Row>
              <Col lg={5}>
                <div>
                  <FeatureBox
                    icon="bar-chart-2"
                    title="Public Transport in Addis Ababa"
                    smallFeatures={this.state.smallFeatures1}
                    desc="Ethiopian Open Source OpenStreetMap-based routes data consuming trip planner application customized by AddisMap Transit allowing enabled the usage of multi-modal transport services under one platform. Hence, it helps public transport users become better informed and save time through the identification of routes, stops, and time schedules. "
                    link="#"
                  />
                </div>
              </Col>

              <Col lg={5} sm={8} className="ml-lg-auto">
                <Card className="border border-light shadow-none mt-5 mt-lg-0">
                  <CardHeader className="border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={transport}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5 pt-5">
              <Col lg={5} sm={8}>
                <Card className="border border-light shadow-none">
                  <CardHeader className=" border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={feature4}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} className="ml-lg-auto">
                <div className="mt-4 mt-lg-0">
                  <FeatureBox
                    icon="pie-chart"
                    title="Fighting Gender Based Violence"
                    smallFeatures={this.state.smallFeatures2}
                    desc="OpenStreetMap Ethiopia developed a secret reporting mechanism for survivors of gender-based violence
                    using an automated phone call or text through any type of phone (smart or non-smart including landline) In collaboration with Ethiopian Telecommunication corporation, installed a mechanism in which a woman with GBV from all over Ethiopia can call or text a specific three to four-digit emergency number."
                    link="#"
                  />
                </div>
              </Col>
            </Row>
            {/*  */}
            <Row>
              <Col lg={5}>
                <div>
                  <FeatureBox
                    icon="bar-chart-2"
                    title="Flood Mapping Using Drone Technology"
                    smallFeatures={this.state.smallFeatures1}
                    desc="OpenStreetMap Ethiopia with support from the Ethiopia Red Cross Society from the 25th to the 28th of the November 2021 set out to assess and determine the extent of the floods in Diredawa and Alaba Town Council in South East District using GIS, Drone Technology and Photogrammetry. The goal of the mission was to document the effects of the floods and the affected towns using Drone Technology and Interviews with the affected Communities."
                    link="#"
                  />
                </div>
              </Col>

              <Col lg={5} sm={8} className="ml-lg-auto">
                <Card className="border border-light shadow-none mt-5 mt-lg-0">
                  <CardHeader className="border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={flood}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5 pt-5">
              <Col lg={5} sm={8}>
                <Card className="border border-light shadow-none">
                  <CardHeader className=" border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={city}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} className="ml-lg-auto">
                <div className="mt-4 mt-lg-0">
                  <FeatureBox
                    icon="pie-chart"
                    title="Ethiopia Open Mapping Program/Open Cities Addis Ababa"
                    smallFeatures={this.state.smallFeatures2}
                    desc="OpenStreetMap Ethiopia with support from the Ethiopia Red Cross Society from the 25th to the 28th of the November 2021 set out to assess and determine the extent of the floods in Diredawa and Alaba Town Council in South East District using GIS, Drone Technology and Photogrammetry. The goal of the mission was to document the effects of the floods and the affected towns using Drone Technology and Interviews with the affected Communities."
                    link="#"
                  />
                </div>
              </Col>
            </Row>
            {/*  */}\
           
            {/*  */}
            <Row>
              <Col lg={5}>
                <div>
                  <FeatureBox
                    icon="bar-chart-2"
                    title="Clean Streets Addis Ababa"
                    smallFeatures={this.state.smallFeatures1}
                    desc="OpenStreetMap Ethiopia with support from the Ethiopia Red Cross Society from the 25th to the 28th of the November 2021 set out to assess and determine the extent of the floods in Diredawa and Alaba Town Council in South East District using GIS, Drone Technology and Photogrammetry. The goal of the mission was to document the effects of the floods and the affected towns using Drone Technology and Interviews with the affected Communities."
                    link="#"
                  />
                </div>
              </Col>

              <Col lg={5} sm={8} className="ml-lg-auto">
                <Card className="border border-light shadow-none mt-5 mt-lg-0">
                  <CardHeader className="border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={feature8}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-5 pt-5">
              <Col lg={5} sm={8}>
                <Card className="border border-light shadow-none">
                  <CardHeader className=" border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={sotm}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} className="ml-lg-auto">
                <div className="mt-4 mt-lg-0">
                  <FeatureBox
                    icon="pie-chart"
                    title="State of The Map Ethiopia"
                    smallFeatures={this.state.smallFeatures2}
                    desc="State of the Map Ethiopia is the annual event for all mappers and OpenStreetMap users from Ethiopia.  Please consider signing up for updates via our newsletter, join our telegram group chat and follow us on Twitter.OSM Ethiopia is committed to providing participants with a safe conference environment following the latest safety and health guidelines set by authorities. 
                    State Of The Map Ethiopia 2023 will take place from 8th - 10th November in Addis Ababa, Ethiopia. Check out the blog for the first ever OpenStreetMap conference after Ethopia."
                    link="#"
                  />
                </div>
              </Col>
            </Row>
            {/*  */}
           
            <Row>
              <Col lg={5}>
                <div>
                  <FeatureBox
                    icon="bar-chart-2"
                    title="Ethiopian FOSS for Community"
                    smallFeatures={this.state.smallFeatures1}
                    desc="FOSS4E is the acronym for Free and Open Source Software for Ethiopia. It is the annual recurring National event hosted by OSM Ethiopia, to support and promote the collaborative development of free and open-source technologies and open geospatial data. The conference covers all kinds of topics related to geospatial information and earth observation openness from Software development to Transition to FOSS4G, Use cases and applications, Education and research, and Open data."
                    link="#"
                  />
                </div>
              </Col>

              <Col lg={5} sm={8} className="ml-lg-auto">
                <Card className="border border-light shadow-none mt-5 mt-lg-0">
                  <CardHeader className="border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={foss}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

           {/*  */}
            <Row className="mt-5 pt-5">
              <Col lg={5} sm={8}>
                <Card className="border border-light shadow-none">
                  <CardHeader className=" border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={refugee}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} className="ml-lg-auto">
                <div className="mt-4 mt-lg-0">
                  <FeatureBox
                    icon="pie-chart"
                    title="Ethiopia Crowdsourcing Refugee Camp Data"
                    smallFeatures={this.state.smallFeatures2}
                    desc="OpenStreetMap Ethiopia partnered with HOT to carry out this program which involved acquiring spatial data in and around refugee settlements to better understand the refugee crisis and also assist partners working in the refugee crisis with information Management and data collection tools with OSM at the core."
                    link="#"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
