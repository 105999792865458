import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import FeatherIcon from "feather-icons-react";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  state = {
    links1: [
      { link: "#", title: "About Us" },
      { link: "/Media", title: "Media & Press" },
      { link: "#", title: "Blog" },
    ],
    links2: [
      { link: "#", title: "For Sponsorship" },
      { link: "#", title: "For CEOs" },
      { link: "#", title: "For Organizations" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col lg={4} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">
                    About the OSMEthiopia
                  </h5>
                  <p>
                    OSMEthiopia is a not only an Organization but an empire of
                    youths where it continues to promotes community mapping,
                    generates map awareness, actively pledges open data sets and
                    builds a network of enthusiastic mappers in Ethiopia.
                  </p>
                </div>
              </Col>
              <Col lg={{ size: 2, offset: 1 }} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">Organization</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {this.state.links1.map((fLink, key) => (
                      <li key={key}>
                        <Link to={fLink.link}>{fLink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col lg={2} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">More Info</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {this.state.links2.map((fLink, key) => (
                      <li key={key}>
                        <Link to={fLink.link}>{fLink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>

              <Col lg={3} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">Contact</h5>

                  <div>
                    <Media>
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>
                      <Media body>
                        <p>Blue Moon , Bole .AA</p>
                      </Media>
                    </Media>
                    <Media>
                      <i>
                        <FeatherIcon
                          icon="mail"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>
                      <Media body>
                        <p>osmethiopia@gmail.com</p>
                      </Media>
                    </Media>
                    <Media>
                      <i>
                        <FeatherIcon
                          icon="phone"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>

                      <Media body>
                        <p>+251-909477449</p>
                      </Media>
                    </Media>
                    <Media>
                      <i>
                        <FeatherIcon
                          icon="phone"
                          className="icon-dual-light icons-sm mt-1 mr-2"
                        />
                      </i>
                      <Media body>
                        <p>+251-943112548</p>
                      </Media>
                    </Media>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
