import React, { Component } from "react";
import { Container } from "reactstrap";
import { data } from "./data";
import AdminBox from "./admin-box";
import OwlCarousel from "react-owl-carousel";
//Import Section Title
import SectionTitle from "../common/section-title";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          icon: "globe",
          title: "Social based",
          desc: "  Working to create a culture of voluntarism in the communityMaking mind changing programs and platforms. OpenStreetMap respects communities and respects people. If you're not already contributing to OSM, consider helping out. If you're already a contributor: thank you.",
        },
        {
          icon: "tv",
          title: "Shared Resource",
          desc: "Place is a shared resource, and when you give all that power to a single entity, you are giving them the power not only to tell you about your location, but to shape it. In summary, there are three concerns: who decides what gets shown on the map, who decides where you are and where you should go, and personal privacy",
        },
        {
          icon: "trending-up",
          title: "Map Content",
          desc: "Making the hub of youth opportunities & creating jobs Creating Landmarks Transforming Cities. In terms of map content, OpenStreetMap is both neutral and transparent. OpenStreetMap is a wiki-like map that anyone in the world can edit. If a store is missing from the map, it can be added in by a store owner or even a customer. ",
        },
        {
          icon: "anchor",
          title: "Our Values",
          desc: " Integrity, Transparency and Accountability: other to ensure cost effective implementation of planned activities Excellence: High level of quality delivery.Flexibility, Equal opportunities.",
        },
        // underpin the approach of all organizational management, volunteers and support staff to their duties and responsibilities. All personnel are expected to be truthful, reliable, and accountable for their actionsTeamwork: is key to the successful achievement of OpenStreetMap Ethiopia’s endeavours,right from the sharing of a common vision, mission and objectives, to the mutual support that individuals are expected to extend to one an
        {
          icon: "check-circle",
          title: "Our Misson",
          desc: "The mission of OpenStreetMap Ethiopia is to have a vibrant OpenStreetMap community in Ethiopia: united, organised and growing to assist, and get involved in National and global development goals.",
        },
        {
          icon: "eye",
          title: "Our Vision",
          desc: "To have a greater capacity for open data, open source, and applying these principles to assist organizations, individuals locally and internationally to manage and solve social, economic and community challenges",
        },
      ],
      responsive: {
        576: {
          items: 1,
        },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="features">
          <Container>
            <SectionTitle
              subtitle="Features"
              title="Key features of the OSMEthiopia"
              desc="Our technological advancement Take your project Performance to the next level. Empower your teams. 
          "
            />
            <div id="homeSlide" className="mt-5">
              <OwlCarousel
                className="owl-theme testi-carousel"
                id="testi-carousel"
                items={1}
                loop={true}
                autoplay
                autoplayTimeout={3000}
                margin={10}
                nav={true}
                responsive={this.state.responsive}
                autoplayHoverPause={true}
                autoplaySpeed
                infinite
              >
                <AdminBox boxs={data.home} />
              </OwlCarousel>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Admin;
