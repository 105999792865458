import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Section Title
import SectionTitle from "../common/section-title";
import Counter from "../../components/Counter/counter";
import ClientBox from "./client-box";

//Import Images
import clinet1 from "../../assets/images/clients/1.png";
import clinet3 from "../../assets/images/clients/3.png";
import clinet4 from "../../assets/images/clients/4.svg";
import clinet6 from "../../assets/images/clients/6.png";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [
        {
          name: "Yared Bekala",
          post: "OSMEthiopia User",
          desc: "Why should you care how people would describe your product? Because it’s an important issue when your product gets described as ‘buggy’ instead of ‘life-saving.’          ",
        },
        {
          name: "G/kidan Kiros",
          post: "OSMEthiopia User",
          desc: "If you don’t want to give your clients any suggestions, you can use the open-ended variation of this customer survey question and ask How would you describe our product",
        },
        {
          name: "Gemechis Roba",
          post: "OSMEthiopia User",
          desc: "Firstly, focus on the most frequent ones. be grateful for any feedback you may receive in that regard. To fail to act on that is a mistake that may cost you too much too soon.",
        },
        {
          name: "Biruke Haile",
          post: "OSMEthiopia User",
          desc: "In research conducted by QuestionPro, 36% of employees said that better visibility of organizational goals would amplify their satisfaction and job performance.",
        },
        {
          name: "Kidus Yared",
          post: "OSMEthiopia User",
          desc: "When employees have a sense of direction how they fit in the long-term broader company vision it does impact job satisfaction positively.",
        },
      ],
      clients2: [
        { image: clinet1 },
        { image: clinet3 },
        { image: clinet4 },
        { image: clinet6 },
      ],
      responsive: {
        576: {
          items: 2,
        },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="clients">
          <Container>
            <SectionTitle
              subtitle="Testimonial"
              title="What our Supporter Say"
              desc="It will be as simple as occidental in fact, it will be Occidental."
            />

            <Row>
              <Col lg={12}>
                <h5 className="mb-4">
                  <i className="mdi mdi-format-quote-open text-primary h1 mr-1"></i>{" "}
                  30 + Satisfied Feedback
                </h5>

                <OwlCarousel
                  className="owl-theme testi-carousel"
                  id="testi-carousel"
                  items={1}
                  loop={true}
                  margin={10}
                  nav={true}
                  responsive={this.state.responsive}
                >
                  <ClientBox clients={this.state.clients} />
                </OwlCarousel>
              </Col>
            </Row>

            <Row className="mt-5">
              {this.state.clients2.map((client, key) => (
                <Col xl={3} sm={6} key={key}>
                  <div className="client-images">
                    <img
                      src={client.image}
                      alt="client-img"
                      className="mx-auto img-fluid d-block"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <section className="section bg-primary">
          <Container>
            <Row className="justify-content-center mb-5">
              <Col lg={7}>
                <div className="text-center text-white-50">
                  <h4 className="text-white">We Stay True For our Heritage</h4>
                  <p>
                    {" "}
                    OSMEthiopia will work on different social issues to solve
                    problems and usher in solutions from untapped potential o
                    young Ethiopians all over the country and abroad
                  </p>
                </div>
              </Col>
            </Row>

            <Counter />
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
