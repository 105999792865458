import React, { Component } from "react";
import { Card, CardBody, Media } from "reactstrap";
import styled from "styled-components";
class AdminBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.props.boxs.map((client, key) => (
          <div key={key} className="item">
            <Card>
              <CardBody className="p-4">
                <Media className="pt-3">
                  <Media body className="align-self-center">
                    <div className="mt-4 bg-white">
                      <ImageWrapper
                        className="d-block slidePhoto"
                        src={client.image}
                        alt="slide-img"
                      />
                    </div>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default AdminBox;

const ImageWrapper = styled.img`
  height: 550px;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 500px;
  }

  @media screen and (max-width: 576px) {
    height: 300px;
  }

  @media screen and (max-width: 375px) {
    width: 350px;
    height: 200px;
  }
`;
