import featuredImg1 from '../../assets/images/showcase/ss1.png';
import featuredImg2 from '../../assets/images/showcase/ss2.png'
import featuredImg3 from '../../assets/images/showcase/ss3.png'
import featuredImg4 from '../../assets/images/showcase/ss4.png'
import featuredImg5 from '../../assets/images/showcase/ss5.png'
import featuredImg6 from '../../assets/images/showcase/ss6.png'
import featuredImg7 from '../../assets/images/showcase/ss7.png';
import featuredImg8 from '../../assets/images/showcase/ss8.png'
import featuredImg9 from '../../assets/images/showcase/ss9.png'
import featuredImg10 from '../../assets/images/showcase/ss10.png'
import featuredImg11 from '../../assets/images/showcase/ss11.png'
import featuredImg12 from '../../assets/images/showcase/ss12.png'
import homeFeature1 from '../../assets/images/showcase/ss7.png'
import homeFeature2 from '../../assets/images/showcase/ss8.png'
import homeFeature3 from '../../assets/images/showcase/ss9.png'
import homeFeature4 from '../../assets/images/showcase/ss10.png'
import homeFeature5 from '../../assets/images/showcase/ss11.png'

export const data = {
  home: [
    {
      id: 'FC1',
      title: 'Connect. Self-express',
      content:
        'Drivers connect and socialize with fellow drivers (i.e. comrades). They self-express by creating personal and group posts, & by livestreaming.',
      image: featuredImg1,
      alt: "alt"
    },
    {
      id: 'FC2',
      title: 'Create and join groups',
      content:
        'Instadriver Groups enables drivers to create & join groups. A tool to help create unity, join a cause, get latest news & take their career to the next level',
      image: featuredImg2,
      alt: "alt"
    },
    {
      id: 'FC3',
      title: 'Livestream',
      content:
        'Livestream can help drivers speak their mind. You can also capture unique experiences and events. Hey, you can broadcast your message to the world!',
      image: featuredImg3,
      alt: "alt"
    },
    {
      id: 'FC4',
      title: 'Group Video Call',
      content:
        'It’s fun video-calling your comrades. You can do business calls too. Know traffic conditions & unfolding events by joining location-based calls.',
      image: featuredImg4,
      alt: "alt"
    },
    {
      id: 'FC5',
      title: 'Online games with comrades',
      content:
        'Uniting drivers through online multiplayer games. Uber drivers, don’t get bored waiting for rides, play Instadriver games with fellow drivers!',
      image: featuredImg5,
      alt: "alt"
    },
    {
      id: 'FC6',
      title: 'Create a Professional Profile',
      content:
        'Create a Professional profile for your driving career. Its LinkedIn for drivers. Mobility employers search & hire drivers from Instadriver, not Linkedin.',
      image: featuredImg6,
      alt: "alt"
    },
      // 
      {
        id: 'FC1',
        title: 'Connect. Self-express',
        content:
          'Drivers connect and socialize with fellow drivers (i.e. comrades). They self-express by creating personal and group posts, & by livestreaming.',
        image: featuredImg7,
        alt: "alt"
      },
      {
        id: 'FC2',
        title: 'Create and join groups',
        content:
          'Instadriver Groups enables drivers to create & join groups. A tool to help create unity, join a cause, get latest news & take their career to the next level',
        image: featuredImg8,
        alt: "alt"
      },
      {
        id: 'FC3',
        title: 'Livestream',
        content:
          'Livestream can help drivers speak their mind. You can also capture unique experiences and events. Hey, you can broadcast your message to the world!',
        image: featuredImg9,
        alt: "alt"
      },
      {
        id: 'FC4',
        title: 'Group Video Call',
        content:
          'It’s fun video-calling your comrades. You can do business calls too. Know traffic conditions & unfolding events by joining location-based calls.',
        image: featuredImg10,
        alt: "alt"
      },
      {
        id: 'FC5',
        title: 'Online games with comrades',
        content:
          'Uniting drivers through online multiplayer games. Uber drivers, don’t get bored waiting for rides, play Instadriver games with fellow drivers!',
        image: featuredImg11,
        alt: "alt"
      },
      {
        id: 'FC6',
        title: 'Create a Professional Profile',
        content:
          'Create a Professional profile for your driving career. Its LinkedIn for drivers. Mobility employers search & hire drivers from Instadriver, not Linkedin.',
        image: featuredImg12,
        alt: "alt"
      },

  ],
  slideContentsData: [
    {
      id: '201',
      image: homeFeature1,
      heading:
        'Recruit drivers instantly and conveniently with our advanced recruitment features.',
      text: 'You can post a vacancy ad, and use the filter and shortlisting features to find the best hire. You can access driver-hire-me cards in your dashboard and add them to your recruitment funnel. Simple and easy process.',
    },
    {
      id: '202',
      image: homeFeature2,
      heading:
        'Vehicle inspection, maintenance & issues management perfected with organised team.',
      text: 'A vehicle requires three things: 1) Proper Inpection; 2. Excellent Mentanance; 3. Timely resolving of emerging issues.  In organisation, we give the tools to achieve this & a working space where teams can actualise it better!',
    },
    {
      id: '203',
      image: homeFeature3,
      heading:
        'Access drivers with verification badge and use the driver management tools.',
      text: 'Having verification badge means the driving competency tests and documents validity check has been done by Instadriver Verification Department. Make your business sustainable with our driver management tools.',
    },
    {
      id: '204',
      image: homeFeature4,
      heading:
        'Get a unique Instadriver url, and showcase your mobility business.',
      text: 'No need of creating a website for your transport business. You can if you want. We give you a space to create a business page and tell the world about your business and prodcuts/services.',
    },
    {
      id: '205',
      image: homeFeature5,
      heading:
        'Join the best directory exclusive for worlds’ transport businesses & mobility players.',
      text: 'Instadriver is creating a unique search engine exclusive for mobility players. By creating an account with Instadriver, you will be searchable on our search engine. You can also search for a specific driver here.',
    },
  ],
  jobcardsTemplates: [
    {
      id: 1,
      is_active: true,
      description:
        'I am very honest and responsible. I promise to take care of the car given to me and ensure my safety as well as that of my employer. Hire me for top notch service.',
    },
    {
      id: 2,
      is_active: false,
      description:
        'I am looking for a driving job. I am a competent driver. I am also very responsible and will take care of your car. Check out my profile and hire me.',
    },
    {
      id: 3,
      is_active: false,
      description:
        'I am looking for a driving job. I am very diligent, attentive and I promise a very professional and committed service to you and your vehicle. I am also very responsible. Check my profile to hire me.',
    },
  ],
  jobDetailsTemplates: [
    {
      id: 1,
      is_active: true,
      description:
        'We are looking for a hardworking and a competent driver . More emphasis is on driving experience, ethics and the ability to work under pressure. We have a great package for the position. All the best',
    },
    {
      id: 2,
      is_active: false,
      description:
        'We are looking for a hardworking and a competent driver . More emphasis is on driving experience, ethics and the ability to work under pressure. We have a great package for the position. All the best.',
    },
    {
      id: 3,
      is_active: false,
      description:
        'We are looking for a hardworking and a competent driver . More emphasis is on driving experience, ethics and the ability to work under pressure. We have a great package for the position. All the best.',
    },
  ],
};
