import React, { useState } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import img1 from "./img/1.jpg";
import img2 from "./img/2.jpg";
import img3 from "./img/3.jpg";
import img4 from "./img/4.jpg";
import img5 from "./img/5.jpg";
import img6 from "./img/6.jpg";
import img7 from "./img/7.jpg";
import img8 from "./img/8.jpg";
import img9 from "./img/9.jpg";
import img10 from "./img/10.jpg";
import img11 from "./img/11.jpg";
import img12 from "./img/12.jpg";
import img13 from "./img/13.jpg";
import img14 from "./img/14.jpg";
import img15 from "./img/15.jpg";
import img16 from "./img/16.jpg";
import img17 from "./img/17.jpg";
import img18 from "./img/18.jpg";
import img19 from "./img/19.jpg";
import img20 from "./img/20.jpg";
import img21 from "./img/21.jpg";
import img22 from "./img/22.jpg";
import img23 from "./img/23.jpg";
import img24 from "./img/24.jpg";
import img25 from "./img/25.jpg";
import img26 from "./img/26.jpg";
import img27 from "./img/27.jpg";
import img28 from "./img/28.jpg";
import img29 from "./img/29.jpg";
import img30 from "./img/30.jpg";
import img31 from "./img/31.jpg";
import img32 from "./img/32.jpg";
import img33 from "./img/33.jpg";
import img34 from "./img/34.jpg";
import img35 from "./img/35.jpg";

import "./gallery.css";

const Section = () => {
  let data = [
    {
      id: 1,
      imgSrc: img1,
    },
    {
      id: 2,
      imgSrc: img2,
    },
    {
      id: 3,
      imgSrc: img3,
    },
    {
      id: 4,
      imgSrc: img4,
    },
    {
      id: 5,
      imgSrc: img5,
    },
    {
      id: 6,
      imgSrc: img6,
    },
    {
      id: 7,
      imgSrc: img7,
    },
    {
      id: 8,
      imgSrc: img8,
    },
    {
      id: 9,
      imgSrc: img9,
    },
    {
      id: 10,
      imgSrc: img10,
    },
    {
      id: 11,
      imgSrc: img11,
    },
    {
      id: 12,
      imgSrc: img12,
    },
    {
      id: 13,
      imgSrc: img13,
    },
    {
      id: 14,
      imgSrc: img14,
    },
    {
      id: 15,
      imgSrc: img15,
    },
    {
      id: 16,
      imgSrc: img16,
    },
    {
      id: 17,
      imgSrc: img17,
    },
    {
      id: 18,
      imgSrc: img18,
    },
    {
      id: 19,
      imgSrc: img19,
    },
    {
      id: 20,
      imgSrc: img20,
    },
    {
      id: 21,
      imgSrc: img21,
    },
    {
      id: 22,
      imgSrc: img22,
    },
    {
      id: 23,
      imgSrc: img23,
    },
    {
      id: 24,
      imgSrc: img24,
    },
    {
      id: 25,
      imgSrc: img25,
    },
    {
      id: 26,
      imgSrc: img26,
    },
    {
      id: 27,
      imgSrc: img27,
    },
    {
      id: 28,
      imgSrc: img28,
    },
    {
      id: 29,
      imgSrc: img29,
    },
    {
      id: 30,
      imgSrc: img30,
    },
    {
      id: 31,
      imgSrc: img31,
    },
    {
      id: 32,
      imgSrc: img32,
    },
    {
      id: 33,
      imgSrc: img33,
    },
    {
      id: 34,
      imgSrc: img34,
    },
    {
      id: 35,
      imgSrc: img35,
    },
  ];

  const [modal, setModal] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const closeModal = () => setModal(!modal);

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModal(true);
  };

  const handleBlur = () => {
    console.log("hello world ");
    // () => setModal(false)
  };

  return (
    <>
      <div className="modalContainer">
        <Modal isOpen={modal} toggle={closeModal} onBlur={handleBlur}>
          <div className="ml-5"></div>
          <ModalBody>
            <button
              style={{
                borderRadius: "5px",
                borderRadius: "",
              }}
              className="primary"
              onClick={closeModal}
            >
              Close
            </button>
            <img
              width="100%"
              height="100%"
              src={tempimgSrc}
              alt="gallery pics"
            />
          </ModalBody>
          <ModalFooter>
            This are activites lead and managed by OSM Ethiopia
          </ModalFooter>
        </Modal>
      </div>

      <div className="gallery">
        {data.map((item, idx) => {
          return (
            <div className="pics" key={idx} onClick={() => getImg(item.imgSrc)}>
              <img src={item.imgSrc} style={{ width: "100%" }} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Section;
