import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings1: [
        {
          title: "The Insight +",
          duration: "Days",
          features: [
            { title: "Scope", value: "Exclusive 5days training" },
            { title: "Onlinespace ", value: "Blue Moon" },
            { title: "Support", value: "Yes" },
          ],
        },
        {
          title: "Open Mapping Lesson",
          duration: "Days",
          features: [
            { title: "Scope", value: "Exclusive 5days training" },
            { title: "Where ", value: "Grand Hall" },
            { title: "Support", value: "Yes" },
          ],
        },
        {
          title: "OpenStreetMap Community Ethiopia",
          duration: "Days",
          features: [
            { title: "Scope", value: "Exclusive 5days training" },
            { title: "Onlinespace ", value: "Blue Moon" },
            { title: "Our Parteners", value: "Capital Hotel, Ambassagors" },
          ],
        },
      ],
      pricings2: [
        {
          title: "Mapping Hands of Ethiopia",
          duration: "Week",
          features: [
            { title: "Scope", value: "Exclusive 5days training" },
            { title: "Onlinespace ", value: "Blue Moon" },
            { title: "Support", value: "Yes" },
          ],
        },
        {
          title: "Introduction to JOSM Lesson",
          duration: "Week",
          features: [
            { title: "Scope", value: "Exclusive 5days training" },
            { title: "Where ", value: "Grand Hall" },
            { title: "Support", value: "Yes" },
          ],
        },
        {
          title: "Tackling Man Made and Natural Disasters",
          duration: "Week",
          features: [
            { title: "Scope", value: "Exclusive 5days training" },
            { title: "Where ", value: "Grand Hall" },
            { title: "Support", value: "Yes" },
          ],
        },
      ],
      activeTab: "1",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="pricing">
          <Container>
            <SectionTitle
              subtitle="Upcomming Events"
              title="Choose your Event"
              desc="Find an event near you. Or let us know if you are planning an event, submit the details"
            />

            <Row>
              <Col lg={12}>
                <div className="text-center mb-4">
                  <Nav pills className="pricing-nav-tabs mb-4">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        Daily
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Weekly
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <PricingBox pricings={this.state.pricings1} />
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <PricingBox pricings={this.state.pricings2} />
                </Row>
              </TabPane>
            </TabContent>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Pricing;
