import React, { Component } from "react";
import { Row } from "reactstrap";

//Import Blog Box
import CounterBox from "./counter-box";

class Counter extends Component {
  state = {
    counters: [
      { icon: "slack", title: "Trainings Held", start: 0, end: 105 },
      { icon: "users", title: "No. of Participants", start: 2, end: 3000 },
      { icon: "umbrella", title: "Our Members", start: 0, end: 708 },
      { icon: "award", title: "Projects Completed", start: 1, end: 6 },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <Row id="counter">
          <CounterBox counters={this.state.counters} />
        </Row>
      </React.Fragment>
    );
  }
}

export default Counter;
