import React, { useState } from "react";
import { Button } from "reactstrap";

import "./register.css";

const Section = () => {
  return (
    <>
      <div className="register">
        <h5 className="title">Become a member</h5>
        <Button
          className="btn-primary gis-btn"
          onClick={() => (window.location.href = "/")}
        >
          Return Home
        </Button>
        <p className="paragraph">
          To help shape the future of OpenStreetMap Ethiopia we would love for
          you to join (or renew) as a member. To do so please go ahead and fill
          our the form below! Members of the Organization must be real people.
          For companies, please contact board@osmet.org to join as an Associate
          Member. In addition to joining as a member allowing you to vote on
          company matters, you will also receive our email newsletter (roughly
          monthly).
        </p>
        <hr />
        {/*  */}
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdsRBRJ3LXzJRVp-u9-JTfVI8XeUGxBzelzL6pGhsbjuVqNyw/viewform?embedded=true"
          width="1540"
          height="2399"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </>
  );
};

export default Section;
