import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          icon: "globe",
          title: "Social based",
          desc: "  Working to create a culture of voluntarism in the communityMaking mind changing programs and platforms. ",
        },
        {
          icon: "tv",
          title: "Shared Resource",
          desc: "Place is a shared resource, and when you give all that power to a single entity, you are giving them the power not only to tell you about your location, but to shape it. ",
        },
        {
          icon: "trending-up",
          title: "Map Content",
          desc: "Making the hub of youth opportunities & creating jobs Creating Landmarks Transforming Cities.",
        },
        {
          icon: "anchor",
          title: "Our Values",
          desc: " Integrity, Transparency and Accountability: other to ensure cost effective implementation of planned activities Excellence: High level of quality delivery.Flexibility, Equal opportunities.",
        },
        // underpin the approach of all organizational management, volunteers and support staff to their duties and responsibilities. All personnel are expected to be truthful, reliable, and accountable for their actionsTeamwork: is key to the successful achievement of OpenStreetMap Ethiopia’s endeavours,right from the sharing of a common vision, mission and objectives, to the mutual support that individuals are expected to extend to one an
        {
          icon: "check-circle",
          title: "Our Misson",
          desc: "The mission of OpenStreetMap Ethiopia is to have a vibrant OpenStreetMap community in Ethiopia: united, organised and growing to assist, and get involved in National and global development goals.",
        },
        {
          icon: "eye",
          title: "Our Vision",
          desc: "To have a greater capacity for open data, open source, and applying these principles to assist organizations, individuals locally and internationally to manage and solve social, economic and community challenges",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            <SectionTitle
              subtitle="Services"
              title="What and How we works"
              desc="The major core things that OSMEthiopia will focused and work on."
            />

            <Row>
              <ServiceBox services={this.state.services} />
            </Row>

            <Row className="mt-4">
              <Col lg={12}>
                <div className="text-center">
                  <Link to="#" className="btn btn-success">
                    View more
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
