import React, { Component } from "react";

//Importing Modal

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section className="hero-section-2 bg-primary" id="home">
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
